import apiClient from '../../../common/http/http-investor';
import config from "../../../config.js";

export async function getDashboardListAPI(requestToken,clientID) {
    try {
        let response = await apiClient.get(`/client/${clientID}/subscription`,{
            headers: {      
                "Authorization": `Bearer ${requestToken}`
            },
        });
       const api_response = {
            success: response.status === 200 ? true : false,
            data: response.data.body.records,
            message: response.data.message    
            
        };
        return api_response;
    } catch (error) {
       
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
   
}

export async function getRiskProfileListAPI(requestToken,clientID) {
    try {
        let response = await apiClient.get(`/client/${clientID}/serviceType/${config.serviceType}/riskprofile`,{
            headers: {      
                "Authorization": `Bearer ${requestToken}`
            },
        });
    
       const api_response = {
            success: response.status === 200 ? true : false,
            data: response.data.body.records,
            message: response.data.message            
        };
        return api_response;
    } catch (error) {       
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
}

export async function getOrderBookListAPI(requestToken,clientID) {
    try {
        let response = await apiClient.get(`/client/${clientID}/investment/orders`,{
            headers: {      
                "Authorization": `Bearer ${requestToken}`
            },
        }); 
        const api_response = {
            success: response.status === 200 ? true : false,
            data: response.data.body.records, 
            message: response.data.message       
        };
        return api_response;
    } catch (error) {       
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
}

export async function getInvestmentListAPI(requestToken,clientID) {
    try {
        let response = await apiClient.get(`/client/${clientID}/investment`,{
            headers: {      
                "Authorization": `Bearer ${requestToken}`
            },
        });
        
       const api_response = {
            success: response.status === 200 ? true : false,          
            data: response.data.body.records, 
            message: response.data.message, 
        };
        return api_response;
    } catch (error) {        
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
}

export async function getPortFolioDataDetailsAPI(requestToken, clientID,portfolioID) {
    try {
        let response = await apiClient.get(`/client/${clientID}/investment/portfolio/${portfolioID}/holding`,{
            headers: {      
                "Authorization": `Bearer ${requestToken}`
            },
        });

        
      
       const api_response = {
            success: response.status === 200 ? true : false,         
          data: response.data.body, 
          message: response.data.message   

        };
        return api_response;
    } catch (error) {        
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
}

export async function getSubDomainExistAPI(subDomain) {
    const params = {
        domain: subDomain,
    };
    try {
        let response = await apiClient.get(`/public/advisor/domain/exists`, {
            params
        });

        const api_response = {
            success: response.status === 200 ? true : false,         
            data: response.data.body, 
            message: response.data.message   
        };
        return api_response;
    } catch (error) {        
        const api_response_1 = {
            success: false,
            data: error,
            message :error.response.data.message,
        };
        return api_response_1;
    }
}